<template>
  <a-modal
    :title="title"
    :width="600"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form" class="aForm">
        <a-form-item label="模板名称" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
          <a-input
            placeholder="请输入模板名称"
            v-decorator="['name', { rules: [{ required: true, message: '请输入模板名称！' }] }]"
          />
        </a-form-item>
        <a-form-item label="节点信息" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input
            placeholder="请输入标签名称"
            v-show="false"
            v-decorator="['nodes', { rules: [{ required: true, message: '请选择审批节点！' }] }]"
          />
          <nodes ref="nodes" @change="setNodesMess"></nodes>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { addTemplate, editTemplate } from '@/api/modular/fileStream/template'
import nodes from '@/views/fileStream/documentSend/components/nodes.vue'
export default {
  components: {
    nodes
  },
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 },
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      title: '添加模板',
      todo: 'add',
      tag: {},
      list: [{ name: '领导审批', val: [], countersign: 1 }],
      csr_list: [],
      mainIds: []
    }
  },
  methods: {
    // 初始化方法
    add({ type, tag }) {
      this.visible = true
      this.title = '添加模板'
      this.todo = type
      if (type === 'edit') {
        this.title = '编辑模板'
        this.initNodes(tag)
      }
    },
    getDataType({ deptId }) {
      return deptId ? 'departmentName' : 'userName'
    },
    setNodesMess(evt) {
      const { list, csrList, mainList } = evt
      this.mainIds = mainList
      this.csr_list = csrList
      this.list = list
      this.form.setFieldsValue({
        nodes: JSON.stringify(mainList)
      })
    },
    initNodes(tag) {
      const { nodeList, name } = tag
      const formatNodes = (nodes) => {
        return nodes.map((j) => ({ ...j, type: this.getDataType(j), id: j.deptId }))
      }
      this.list = nodeList.filter((j) => ![99, 100].includes(j.sort)).map((i) => ({ name: i.name, val: formatNodes(i.userList) }))
      this.csr_list = nodeList
        .find((j) => j.sort === 100)
        .userList.map((j) => ({ ...j, type: this.getDataType(j), id: j.deptId }))
      this.mainIds = nodeList
        .find((j) => j.sort === 99)
        .userList.map((j) => ({ ...j, type: this.getDataType(j), id: j.deptId }))
      this.tag = tag
      this.$nextTick(() => {
        this.$refs.nodes.initData({ mainList: this.mainIds, list: this.list, csrList: this.csr_list })
        this.form.setFieldsValue({ nodes: JSON.stringify(this.list), name })
      })
    },
    clearForm() {
      this.list = [{ name: '领导审批', val: [], countersign: 1 }]
      this.csr_list = []
      this.mainIds = []
      this.form.resetFields()
      this.$refs.nodes.reset()
    },
    getResApi(data) {
      if (this.todo === 'edit') return editTemplate(data)
      return addTemplate(data)
    },
    /**
     * 提交表单
     */
    handleSubmit() {
      if (this.list.some((j) => !j.val || j.val.length === 0)) {
        this.$message.warning('存在节点没有选择人员！')
        return
      }
      if (!this.csr_list || !this.csr_list.length) {
        this.$message.warning('请选择抄送人')
        return
      }
      const {
        form: { validateFields },
      } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          const arr = []
          this.list.forEach((el, i) => {
            const uid = []
            el.val.forEach((el) => {
              if (el.type === 'departmentName') {
                uid.push({ deptId: el.id, corpId: el.corpId })
              } else if (el.type === 'userName') {
                uid.push({ userId: el.userId, corpId: el.corpId, status: 0 })
              }
            })
            arr.push({ name: el.name, sort: i, userList: uid, countersign: el.countersign })
          })
          // eslint-disable-next-line camelcase
          const csr_uid = []
          this.csr_list.forEach((el) => {
            if (el.type === 'userName') {
              csr_uid.push({ userId: el.userId, corpId: el.corpId, status: 0 })
            } else if (el.type === 'departmentName') {
              csr_uid.push({ deptId: el.id, corpId: el.corpId })
            }
          })
          arr.push({
            name: '接收人',
            sort: 99,
            userList: this.mainIds.map(j => ({
              userId: j.userId, corpId: j.corpId, status: 0
            })) })
          arr.push({ name: '抄送人', sort: 100, userList: csr_uid })
          const data = {
            name: values.name,
            nodeList: arr
          }
          if (this.todo === 'edit') data.id = this.tag.id
          this.getResApi(data)
            .then((res) => {
              if (res.success) {
                this.$message.success(this.todo === 'edit' ? '编辑成功' : '新增成功')
                this.confirmLoading = false
                this.$emit('refresh')
                this.clearForm()
                this.visible = false
              } else {
                this.$message.error(res.message)
              }
            })
            .finally((res) => {
              this.confirmLoading = false
            })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.clearForm()
      this.visible = false
    }
  }
}
</script>
<style lang="less" scoped>
@import url('./index');
.aForm {
  max-height: 500px;
  overflow-y: auto;
}
</style>
